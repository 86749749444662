import React from "react"
import { Link } from "gatsby"

export default function Footer(props){
    return (
        <footer id="footer">
            <div className="left">
                <nav>
                    <Link to="/">Home</Link> | <Link to="/services">Services</Link> | <Link to="/work">Work</Link> | <Link to="/about">About</Link> | <Link to="/contact">Contact</Link> | <Link to="/blog">News</Link> | <Link to="/games">Games</Link>
                </nav>
                <div className="minicontact">
                    <p>+32 498 77 09 41 | <a href="mailto:gilles@lionstigersandbears.be">gilles@lionstigersandbears.be</a></p>
                </div>
            </div>
            <div className="footerslogan">
                <h3>untamed <em>creativity</em> fierce <em>artistry</em> strong <em>technology</em>, oh my.</h3>
            </div>
        </footer>
    )
}