import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, Link, graphql } from "gatsby"
import {TweenLite} from "gsap"
import { useState, useEffect } from 'react';
import { Link as ScrollLink, Events, scrollSpy } from 'react-scroll'
import LogoSVG from "../assets/svg/ltb-logo-landscape_original.svg"
import gsap from "gsap/gsap-core";


const { useRef } = React;



const ListLink = props => (
    <li>
      <Link to={props.to} activeClassName="active" partiallyActive={props.partiallyActive} className={props.className}><span>{props.children}</span></Link>
    </li>
  )

  const ListScrollLink = props => (
      <li>
          <ScrollLink className={props.className} to={props.to} activeClass="active" spy={true} smooth={true} offset={0} duration={500} containerId={props.containerId}><span>{props.children}</span></ScrollLink>
      </li>
  )
  

const HomepageNav = props => (
    <ul id="homepagenav">
        <ListScrollLink className="active" containerId="mainScrollContainer" to="intro" >Welcome</ListScrollLink>
        <ListLink to="/about/" partiallyActive={true}>About</ListLink>
        <ListLink to="/work/" partiallyActive={true}>Work</ListLink>  
        <ListLink to="/services/" partiallyActive={true}>Services</ListLink>   
        <ListLink to="/contact/" partiallyActive={true}>Contact</ListLink>
        <ListLink to="/blog/" partiallyActive={true}>News</ListLink>
        <ListLink to="/games" partiallyActive={false}>Games</ListLink>
    </ul>
)

const StandardNav = props => (
    <ul id="standardnav">
        <ListLink to="/">Welcome</ListLink>
        <ListLink to="/about/" partiallyActive={true}>About</ListLink>
        <ListLink to="/work/" partiallyActive={true}>Work</ListLink>  
        <ListLink to="/services/" partiallyActive={true}>Services</ListLink> 
        <ListLink to="/contact/" partiallyActive={true}>Contact</ListLink>
        <ListLink to="/blog/" partiallyActive={true}>News</ListLink>
        <ListLink to="/games" partiallyActive={false}>Games</ListLink>
    </ul>
)

class RoundMenuButton extends React.Component {
    render(){
        const {forwardedRef} = this.props;

        return(
            <Link className={this.props.className} ref={forwardedRef} to={this.props.to} onClick={this.props.onClick} ><span><i className={this.props.icon_className}></i>{this.props.children}</span></Link>
        )
    }
}

function Navigation(props){
    if(props.homepage){
        return <HomepageNav></HomepageNav>
    }else{
        return <StandardNav></StandardNav>
    }
}

class MainNavigation extends React.Component {

    constructor(props){
        super(props);

        this.mainNavRef = React.createRef();
        this.hamburgerMenuButtonRef = React.createRef();
        this.closeMobileNavRef = React.createRef();
        this.hashChangedHandler = null;

        this.fadeDuration = .5;
    }

    componentDidMount(){
        // listen to hash changes
        this.hashChangedHandler = ()=>{ this.onHashChanged(); }
        window.addEventListener("hashchange", this.hashChangedHandler, false);

        window.addEventListener("scroll", function(event){
            // console.log(window.scrollY);
            if(window.scrollY > 60){
                gsap.to("header .headerShadow", {
                    className: "headerShadow scrolled"
                })
            }else {
                gsap.to("header .headerShadow", {
                    className: "headerShadow"
                })
            }
        }, {passive: true})

        // find anchors and add a click event
        let homepagenav = document.getElementById("homepagenav");
        if(homepagenav){
            homepagenav.childNodes.forEach((value, key)=>{
                // console.log(value); // should be li's
                // first child is a
                value.firstChild.addEventListener("click", (event)=>{
                    this.HideMobileNav();
                });
            })
        }

        Events.scrollEvent.register("begin", function() {
            // console.log("begin", arguments);
            // gsap.to("header .headerShadow", {
            //     className: "headerShadow scrolled"
            // })
        });
    
        Events.scrollEvent.register("end", function() {
            // console.log("end", arguments);
        });

        scrollSpy.update();
    }

    componentWillUnmount(){
        window.removeEventListener("hashchange", this.hashChangedHandler, false);
        Events.scrollEvent.remove("begin");
        Events.scrollEvent.remove("end");
    }

    onHashChanged(){
        this.HideMobileNav();
    }

    OnResize(windowSize){
        this.HideMobileNav();
    }

    HideMobileNav(){
        this.mainNavRef.current.style = {};
        this.hamburgerMenuButtonRef.current.style = {};
        this.closeMobileNavRef.current.style = {};
    }

    OnHamburgerMenuClick(event){
        event.preventDefault();
        TweenLite.to(this.mainNavRef.current, { x: -global.innerWidth, duration: 0 })
        TweenLite.to(this.mainNavRef.current, { autoAlpha: 1, x: 0, duration: this.fadeDuration, display:'block' })
        TweenLite.to(this.closeMobileNavRef.current, { autoAlpha: 1, duration: this.fadeDuration })
        TweenLite.to(this.hamburgerMenuButtonRef.current, {autoAlpha: 0, duration: this.fadeDuration })
    }

    OnMobileNavCloseClick(event){
        event.preventDefault();

        TweenLite.to(this.mainNavRef.current, { x: -global.innerWidth, duration: this.fadeDuration, display:'none' })
        TweenLite.to(this.closeMobileNavRef.current, { autoAlpha: 0, duration: this.fadeDuration })
        TweenLite.to(this.hamburgerMenuButtonRef.current, {autoAlpha: 1, duration: this.fadeDuration })
    }

    render(){
        return(
        <nav id="main_nav">
            <div className="links" ref={this.mainNavRef}>
                <Navigation homepage={this.props.homepage} />
            </div>
            <RoundMenuButton to="#" className="mobile_menu_link" icon_className="icon-hamburger-menu" forwardedRef={this.hamburgerMenuButtonRef} onClick={(event)=>{ this.OnHamburgerMenuClick(event); }}><span>Navigation</span></RoundMenuButton>
            <RoundMenuButton to="#" className="mobile_menu_close_link" icon_className="icon-close-round-line" forwardedRef={this.closeMobileNavRef} onClick={(event)=>{ this.OnMobileNavCloseClick(event); }}><span>Close</span></RoundMenuButton>
        </nav>
        )
    }
}

// src: https://usehooks.com/useWindowSize/
// Hook
function useWindowSize(props) {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    const { onResize } = props; 
  
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });

        if(typeof onResize === "function"){
            onResize({
                width: window.innerWidth,
                height: window.innerHeight,
              });
        }
      }
      
      // Add event listener
      window.addEventListener("resize", handleResize);
      
      // Call handler right away so state gets updated with initial window size
      handleResize();
      
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // react-hooks/exhaustive-deps
    // Empty array ensures that effect is only run on mount
  
    return windowSize;
  }

export default function Header(props){
    const data = useStaticQuery(
        graphql `
            query {
                site{
                    siteMetadata {
                        title
                        description
                        share_image
                        canonical_url
                    }
                }
            }
        `
    )

    const description_metadata = props.description ? props.description : data.site.siteMetadata.description;

    // reference to main navigation
    const mainNavRef = useRef();
        
    // listen to window.resize and pass the event down the chain
    useWindowSize({
        onResize: function(dimensions){
            mainNavRef.current.OnResize(dimensions);
        }
    }); //const size = 

    return (
        <header>
            <Helmet async={false}>
                <title>{props.pageTitle ? props.pageTitle + " - " + data.site.siteMetadata.title : data.site.siteMetadata.title }</title>
                <link rel="canonical" href={data.site.siteMetadata.canonical_url} />

                <meta name="title" content={props.pageTitle ? props.pageTitle + " - " + data.site.siteMetadata.title : data.site.siteMetadata.title } />
                <meta name="description" content={description_metadata} />

                <meta property="og:type" content="website" />
                <meta property="og:url" content={data.site.siteMetadata.canonical_url} />
                <meta property="og:title" content={props.pageTitle ? props.pageTitle + " - " + data.site.siteMetadata.title : data.site.siteMetadata.title } />
                <meta property="og:description" content={description_metadata} />
                <meta property="og:image" content={data.site.siteMetadata.share_image} />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={data.site.siteMetadata.canonical_url} />
                <meta property="twitter:title" content={props.pageTitle ? props.pageTitle + " - " + data.site.siteMetadata.title : data.site.siteMetadata.title } />
                <meta property="twitter:description" content={description_metadata} />
                <meta property="twitter:image" content={data.site.siteMetadata.share_image}/>

                <link rel="apple-touch-icon" sizes="180x180" href="./apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="./favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="./favicon-16x16.png" />
                <link rel="manifest" href="./site.webmanifest" />
                <link rel="mask-icon" href="./safari-pinned-tab.svg" color="#fcff00" />
                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="theme-color" content="#ffffff" />
            </Helmet>

            <h1 id="logo">
                { !props.homepage && (
                    <Link to="/">
                        <img src={LogoSVG} alt={data.site.siteMetadata.title}></img>
                    </Link>
                ) }
                
                { props.homepage && (
                    <ScrollLink containerId="mainScrollContainer" to="intro" smooth={true} offset={0} duration={500}>
                        <img src={LogoSVG} alt={data.site.siteMetadata.title}></img>
                    </ScrollLink>
                )}

            </h1>

            <MainNavigation homepage={props.homepage} ref={mainNavRef}></MainNavigation>
            
            <div className="headerShadow"></div>
        </header>
    )
}