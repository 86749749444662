import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import "../scss/styles.scss";


export default function Layout( props ) {
    return (
        <div id="ltb-site-container" className={props.className} >
            <Header pageTitle={props.pageTitle} description={props.description} ></Header>
            <div id="layout-content">
                {props.children}
            </div>

            { !props.hideFooter && (
                <Footer></Footer>
            ) }
            
        </div>
    )
}